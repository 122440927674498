*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  /* 10px */ }
  @media (max-width: 85.5em) {
    html {
      font-size: 50%; } }
  @media (max-width: 62.5em) {
    html {
      font-size: 40%; } }

body {
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box; }

li {
  list-style: none; }

a {
  text-decoration: none; }

section {
  max-width: 100vw;
  scroll-margin-top: 7.9rem;
  -webkit-scroll-snap-margin-top: 7.9rem; }

::-webkit-scrollbar {
  width: 10px; }

::-webkit-scrollbar-track {
  background: #070707; }

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #616161; }

::-webkit-scrollbar-thumb:hover {
  background: #808080; }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: skew(-30deg) translateX(0); }
  100% {
    opacity: 0.3;
    transform: skew(-30deg) translateX(-50%); } }

@keyframes moveInLeftSmall {
  0% {
    opacity: 0;
    transform: skew(-50deg) translateX(0); }
  100% {
    opacity: 0.3;
    transform: skew(-50deg) translateX(-10%); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: skew(70deg) translateX(-100%); }
  100% {
    opacity: 0.3;
    transform: skew(70deg) translateX(-60%); } }

@keyframes moveInRightSmall {
  0% {
    opacity: 0;
    transform: skew(50deg) translateX(0); }
  100% {
    opacity: 0.3;
    transform: skew(50deg) translateX(-50%); } }

@keyframes navbarGrowSmall {
  0% {
    opacity: 0;
    width: 0%; }
  100% {
    opacity: 1;
    width: 15%; } }

@keyframes navbarGrowBig {
  0% {
    opacity: 0;
    width: 0%; }
  100% {
    opacity: 1;
    width: 60%; } }

@keyframes navbarShrinkSmall {
  0% {
    opacity: 1;
    width: 15%; }
  100% {
    opacity: 0;
    width: 0%; } }

@keyframes navbarShrinkBig {
  0% {
    opacity: 1;
    width: 60%; }
  100% {
    opacity: 0;
    width: 0%; } }

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.u-center-text {
  text-align: center !important; }

.u-mbs {
  margin-bottom: 1.5rem !important; }

.u-mbm {
  margin-bottom: 4rem !important; }

.u-mbb {
  margin-bottom: 8rem !important; }

.u-mtm {
  margin-top: 4rem !important; }

.u-mtb {
  margin-top: 8rem !important; }

.u-mth {
  margin-top: 10rem !important; }

.i-list {
  pointer-events: none;
  padding: 0 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem; }
  .i-list:hover > * {
    filter: blur(3px) grayscale(100%); }
  .i-list__item {
    pointer-events: auto;
    position: relative;
    transition: all 0.3s;
    cursor: pointer; }
    .i-list__item:hover {
      filter: blur(0) grayscale(0%) !important;
      z-index: 100;
      transform: scale(1.2); }
    .i-list__item:hover .i-list__item--text {
      width: max-content;
      padding-left: 3rem;
      color: #fff;
      opacity: 1; }
    .i-list__item--img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 10;
      padding: 2rem;
      border-radius: 50%;
      background-color: #232323; }
    .i-list__item--text {
      position: absolute;
      top: 50%;
      left: 70%;
      z-index: 1;
      font-weight: 600;
      text-transform: uppercase;
      transform: translateY(-50%);
      transition: all 0.4s;
      background-color: rgba(0, 35, 102, 0.8);
      color: transparent;
      opacity: 0;
      display: inline-block;
      width: 0;
      padding: 0.5rem;
      border-radius: 3px; }
      .i-list__item--text:hover {
        display: none !important; }
  @media (max-width: 50em) {
    .i-list__item img {
      width: 4rem !important;
      height: 4rem !important; } }

.traits {
  display: grid;
  grid-template-columns: 0.8fr 1fr 1fr;
  grid-template-rows: repeat(2, 21rem);
  gap: 2rem;
  pointer-events: none; }
  @media (max-width: 115em) {
    .traits {
      grid-template-rows: repeat(2, 25rem); } }
  @media (max-width: 100em) {
    .traits {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(2, 20rem); } }
  .traits:hover .traits__box {
    filter: blur(1px) grayscale(80%);
    background-color: rgba(35, 35, 35, 0.9); }
  .traits__box:hover {
    filter: blur(0) grayscale(0%) !important;
    background-color: rgba(255, 255, 255, 0.9) !important; }
  .traits__box {
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.85);
    color: #777;
    padding: 2rem;
    border-radius: 3px;
    font-size: 1.6rem;
    transition: all 0.2s; }
    .traits__box--1 {
      grid-column: 1 / 2;
      grid-row: 1 / 3; }
    .traits__box:hover {
      transform: translateY(-3px); }
  .traits__header h4 {
    font-size: 2.2rem;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 1rem; }
  .traits__header i {
    opacity: 0.6;
    font-size: 3rem; }
  .traits__text {
    display: flex;
    justify-content: center;
    gap: 1rem; }
    .traits__text i {
      font-size: 3rem; }
    .traits__text p {
      letter-spacing: 0.25rem; }
  .traits__i-taught {
    color: #323272; }
  .traits__i-reader {
    color: #ce2e2e; }
  .traits__i-analytical {
    color: #792d79; }
  .traits__i-coder {
    color: #ff6435; }
  .traits__i-calm {
    color: #286428; }

.svg-wrap {
  position: absolute;
  width: 0px;
  height: 0px;
  overflow: hidden; }

.svg-container {
  position: relative;
  z-index: 1;
  /* needed for setting pseudo-element z-index */
  overflow: hidden;
  backface-visibility: hidden; }

.svg-container span {
  position: relative; }

.svg-container span::before,
.svg-container span::after {
  pointer-events: none;
  backface-visibility: hidden; }

.link-svgline span svg.link-svgline {
  position: absolute;
  top: 48%;
  left: 5%;
  width: 100%;
  height: 15px;
  fill: none;
  stroke: rgba(255, 180, 94, 0.356);
  stroke-width: 30; }

.vignettes {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
  height: 60rem;
  margin-bottom: 6rem;
  background: linear-gradient(to right, rgba(16, 8, 44, 0.5), rgba(0, 0, 0, 0.4)), url("../imgs/about/vignettes-background.webp");
  background-size: cover;
  background-position: center; }
  .vignettes__box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 6rem 8rem;
    max-width: 110rem; }
    @media (max-width: 47em) {
      .vignettes__box {
        flex-direction: column; } }
  .vignettes__p {
    text-align: center;
    font-size: 2rem;
    letter-spacing: 0.25rem;
    padding: 3.5rem 5rem 5rem 5rem;
    height: max-content;
    background: rgba(12, 12, 12, 0.8);
    color: white;
    transition: all 0.4s !important; }
    .vignettes__p--1 {
      clip-path: polygon(0 5%, 95% 0, 95% 90%, 5% 100%);
      clip-path: polygon(0 5%, 100% 0, 95% 80%, 69% 84%, 80% 100%, 50% 85%, 5% 90%); }
    .vignettes__p--2 {
      margin-top: 10rem;
      clip-path: polygon(5% 0, 100% 5%, 90% 100%, 5% 95%);
      clip-path: polygon(5% 0, 100% 8%, 90% 90%, 51% 88%, 20% 100%, 25% 89%, 5% 90%); }
    @media (max-width: 47em) {
      .vignettes__p {
        clip-path: none; }
        .vignettes__p--2 {
          margin-top: 0; } }
  .vignettes__p:first-child:hover {
    transform: translate(-1rem, -2rem); }
  .vignettes__p:not(:first-child):hover {
    transform: translate(1rem, -2rem); }

.languages {
  border: 1px solid #ebebeb;
  padding: 3rem 20rem;
  width: 90vw;
  max-width: 150rem;
  margin: 4rem; }
  @media (max-width: 44em) {
    .languages {
      border: none; }
      .languages img {
        width: 8rem;
        height: auto; } }
  .languages__title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    padding: 0 2rem;
    font-size: 3rem;
    color: #4d4d4d;
    text-transform: uppercase; }
  .languages__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin: 5rem auto; }
  .languages__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem; }
  .languages__level-box {
    position: relative; }
    .languages__level-box p {
      font-size: 1.6rem;
      font-weight: 600;
      margin-bottom: -0.5rem;
      margin-top: 0.25rem;
      color: #5f5f5f; }
    .languages__level-box span {
      background-color: rgba(0, 35, 102, 0.9);
      color: #fff;
      font-size: 1.6rem;
      padding: 0.25rem 0.5rem;
      border-radius: 3px; }
    .languages__level-box span,
    .languages__level-box p {
      margin-left: 2rem; }

.level {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 1.5rem; }
  .level__point {
    height: 0.7rem;
    width: 5rem;
    background-color: #d1d1d1;
    border-radius: 3px;
    transform: skewX(-50deg); }
  .level__colored {
    background-color: #ff916a; }

.carousel {
  margin: 0 auto;
  max-width: 1200px; }
  .carousel__img-box {
    background: #181818;
    border-radius: 7px;
    max-width: 900px;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 50px; }
  .carousel__img {
    width: 100%;
    height: auto; }
  @media (max-width: 1000px) {
    .carousel__img-box {
      border-radius: 0px; }
    .carousel__img {
      width: 100%;
      height: auto; } }

.header {
  padding: 5rem 6rem 2rem 6rem; }

.heading {
  margin-top: -1rem;
  font-size: 5rem; }

.subheading {
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #d48c07; }

@media (max-width: 1200px) {
  .header {
    padding: 2rem 3rem; } }

@media (max-width: 600px) {
  .heading {
    font-size: 4rem; } }

.btn--component {
  min-width: 160px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0.8rem 1.2rem;
  border-radius: 7px;
  margin: 1rem;
  transition: all 0.4s; }

.btn--component:hover,
.btn--component:focus {
  background: transparent; }

.default {
  color: #2e2e2e;
  background: white; }

.default:hover,
.default:focus {
  color: white;
  box-shadow: inset 0 0 0 3px white; }

.banner {
  position: relative;
  z-index: 1999 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: linear-gradient(150deg, #5252f1, #2c1366);
  opacity: 0;
  height: 0;
  transition: all 0.8s; }
  .banner p {
    font-size: 1.6rem;
    margin: 0; }
  .banner i {
    cursor: pointer;
    position: absolute;
    right: 4rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem; }
  .banner__link:link, .banner__link:visited {
    font-weight: bold;
    color: white;
    transition: all 0.2s; }
  .banner__link:hover, .banner__link:active {
    color: #f59f00;
    text-decoration: underline; }
  .banner--active {
    height: 3rem;
    opacity: 1; }

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999 !important;
  width: 100%;
  padding: 2rem 10rem;
  transition: all 0.4s; }
  @media (max-width: 56.25em) {
    .navbar {
      padding: 2rem 6rem; } }
  @media (max-width: 37.5em) {
    .navbar {
      flex-direction: column;
      padding: 1rem 4rem; } }
  .navbar::before, .navbar::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: white;
    transition: all 1s ease-out;
    width: 0; }
  .navbar::before {
    animation: navbarGrowSmall 0.7s ease-out 0.8s forwards;
    left: 0; }
  .navbar::after {
    right: 0;
    animation: 0.7s navbarGrowBig ease-out 0.8s forwards; }
  .navbar__img {
    position: relative;
    z-index: 10;
    height: 10rem;
    width: 10rem;
    transition: all 1s; }
  .navbar__list {
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    width: 67%; }
    @media (max-width: 56.25em) {
      .navbar__list {
        width: 80%;
        gap: 4rem; } }
    @media (max-width: 37.5em) {
      .navbar__list {
        width: 100%; } }
  .navbar__item {
    display: flex;
    align-items: center;
    position: relative;
    gap: 1rem;
    transition: all 0.2s ease-in-out; }
  .navbar__link:before {
    content: "";
    position: absolute;
    bottom: -5px;
    width: 0;
    height: 2px;
    transition: all 0.2s ease-in-out;
    transition-duration: 0.45s;
    opacity: 0;
    background-color: #f59f00;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px; }
  .navbar__link:hover:before {
    width: 100%;
    opacity: 1; }
  .navbar__link:link, .navbar__link:visited {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 2rem;
    width: 110%;
    color: white;
    text-decoration: none;
    transition: all 0.2s; }
  .navbar__link:hover {
    color: #f59f00; }
  .navbar__item--active {
    padding: 1rem 2rem;
    background-color: rgba(14, 14, 14, 0.5); }
  .navbar__item--active a:link,
  .navbar__item--active a:visited {
    color: crimson; }
  .navbar__item--active a::before,
  .navbar__item--active a::after {
    width: 0 !important; }
  .navbar__span {
    color: white; }
  @media (max-width: 500px) {
    .navbar__link {
      flex-direction: column;
      gap: 5px; } }

.shrank-navbar {
  position: fixed;
  padding: 1rem 10rem;
  background-color: rgba(7, 7, 7, 0.9);
  backdrop-filter: blur(2px); }
  @media (max-width: 56.25em) {
    .shrank-navbar {
      padding: 1rem 6rem; } }
  .shrank-navbar .navbar__item--active {
    background-color: rgba(255, 255, 255, 0.1); }
  .shrank-navbar a:link,
  .shrank-navbar a:visited {
    font-size: 1.6rem; }
  .shrank-navbar img {
    height: 6rem;
    width: 6rem; }

.set-navbar::before {
  animation: 0.7s navbarGrowSmall ease-out forwards; }

.set-navbar::after {
  animation: 0.7s navbarGrowBig ease-out forwards; }

.shrank-navbar::before {
  animation: navbarShrinkSmall 0.7s ease-out forwards; }

.shrank-navbar::after {
  animation: navbarShrinkBig 0.7s ease-out forwards; }

.hero {
  position: relative;
  height: 100vh; }
  .hero__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff; }
  .hero__content-box {
    padding-left: 2.5rem;
    display: flex; }
  .hero__greeting, .hero__professions {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0; }
  .hero__greeting {
    font-size: 1.4rem; }
  .hero__greeting::before {
    content: "";
    width: 2rem;
    height: 2px;
    background-color: #ffa909; }
  .hero__title {
    position: relative;
    font-size: 6rem; }
  .hero__title--first {
    position: absolute;
    font-size: 2rem;
    top: 2rem;
    left: -3.2rem; }
  .hero__typing {
    background: linear-gradient(to right, #ffa909, #eb3434);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3rem; }
  .hero__professions {
    position: relative;
    align-self: flex-start;
    font-size: 1.5rem;
    padding-left: 4rem; }
  .hero__professions:before {
    content: "";
    position: absolute;
    top: 0;
    left: 1rem;
    background-color: white;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 2rem;
    width: 2rem; }
  .hero__heading-box {
    display: flex;
    flex-direction: column;
    align-items: center; }

.about__programming-languages {
  background: linear-gradient(to right, rgba(116, 72, 42, 0.6), rgba(28, 20, 75, 0.5)), url("/src/imgs/about/programming-languages-background.webp");
  background-size: cover;
  background-position: center; }

.about__operating-systems {
  background: linear-gradient(to right, rgba(20, 16, 56, 0.6), rgba(75, 20, 20, 0.5)), url("/src/imgs/about/systems-background.webp");
  background-size: cover;
  background-position: bottom; }

.about__technologies {
  background: linear-gradient(to right, rgba(116, 72, 42, 0.6), rgba(28, 20, 75, 0.5)), url("/src/imgs/about/tech-background.webp");
  background-size: cover;
  background-position: center; }

.about__traits {
  background: linear-gradient(to right, rgba(14, 5, 71, 0.5), rgba(62, 42, 116, 0.6)), url("/src/imgs/about/traits-background.webp");
  background-size: cover;
  background-position: center; }

.about__container {
  padding: 4rem 10rem;
  width: 70vw;
  min-height: 40rem;
  margin: 0 auto;
  display: grid;
  grid-template-rows: 0.5fr 4fr;
  align-items: center;
  justify-content: center; }
  .about__container h3 {
    color: #fff;
    text-transform: uppercase;
    font-size: 3rem;
    align-self: baseline; }
  @media (max-width: 87.5em) {
    .about__container {
      padding: 4rem 5rem;
      width: 90vw; } }

.poly-shape--1 {
  clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%); }

.poly-shape--2 {
  clip-path: polygon(0 0, 95% 0, 100% 100%, 5% 100%); }

@media (max-width: 87.5em) {
  .poly-shape--1, .poly-shape--2 {
    clip-path: none; } }

.courses {
  background: linear-gradient(to bottom, #0f0f0f, #0a0a0a);
  color: white;
  padding: 2rem 2rem 6rem 2rem; }

@media (max-width: 965px) {
  .courses {
    padding: 2rem 2rem 2rem; } }

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  height: calc(100vh - 8rem);
  color: white;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)), url("/src/imgs/road.webp");
  background-size: cover;
  background-position: center;
  background-attachment: fixed; }
  .contact__heading {
    font-size: 1.6rem; }
  .contact__heading h2 {
    font-size: 6rem; }
  .contact__heading p:first-child {
    font-size: 2rem;
    margin-bottom: -1rem;
    color: #ffa909; }
  .contact__list {
    display: flex;
    gap: 3rem;
    font-size: 2.4rem; }
  .contact__item {
    display: flex;
    align-items: center;
    gap: 10px; }
  .contact__link:link, .contact__link:visited {
    color: #e69500;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.4s;
    padding: 10px;
    border-radius: 20px; }
  .contact__link:hover {
    background-color: #141414;
    color: white;
    transform: scale(1.1) translateY(-10px);
    box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.2); }
  .contact__link:hover i,
  .contact__link i {
    color: #e69500; }
  @media (max-width: 800px) {
    .contact__list {
      flex-direction: column;
      gap: 20px; } }

.footer {
  color: #fff;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: #232323;
  background: linear-gradient(to bottom, #070707, #030303);
  background-size: cover;
  background-position: center; }

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.main-content p {
  margin: 0 !important; }

.footer-link:link,
.footer-link:visited {
  color: #d82020;
  font-weight: 500;
  font-size: 1.6rem;
  border-bottom: 1px solid transparent;
  padding: 3px; }

.credits:link,
.credits:visited {
  color: #eb3434;
  font-size: 1.4rem;
  border-bottom: 1px solid transparent; }

.footer-link:hover,
.credits:hover {
  color: #f59a9a;
  border-bottom: 1px solid #f59a9a; }

.credits-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  border-bottom: 1px solid transparent; }

#about,
#portfolio {
  background-color: #fff;
  color: #2e2e2e; }

.hero__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.hero__content::after,
.hero__content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  background-image: linear-gradient(120deg, transparent, #290e29, #1c1c3a, transparent);
  opacity: 0.3;
  animation-delay: 0.8s; }

.hero__content::before {
  width: 60%;
  transform: skew(-30deg) translateX(-50%);
  animation: 1s moveInLeft ease-out; }

.hero__content::after {
  width: 100%;
  transform: skew(70deg) translateX(-60%);
  animation: 1s moveInRight ease-out; }

#hero {
  background: url("/src/imgs/hero-background.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  max-width: 100vw; }

#hero * {
  z-index: 100; }

@media (max-width: 1200px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: none; } }

@media (max-width: 965px) {
  .hero__content::after {
    width: 200%; }
  .hero__content::before {
    width: 100%; } }

@media (max-width: 665px) {
  .hero__content::after,
  .hero__content::before {
    width: 150%; }
  .hero__content::before {
    transform: skew(-50deg) translateX(-10%); }
  .hero__content::after {
    transform: skew(50deg) translateX(-50%); }
  .hero__content::before {
    transform: skew(-50deg) translateX(-10%);
    animation: 1s moveInLeftSmall ease-out; }
  .hero__content::after {
    transform: skew(50deg) translateX(-50%);
    animation: 1s moveInRightSmall ease-out; } }

.info-container {
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem; }

.box {
  color: white;
  padding: 2rem; }

/* TITLE
--------------------------------------------- */
.list-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  padding-bottom: 2rem;
  text-transform: uppercase; }

.text {
  width: 35%;
  padding: 0 5px;
  text-align: center; }

/* XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX */
.list {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px; }

.traits-heading {
  font-size: 1.1rem;
  font-weight: 500; }

@media (max-width: 600px) {
  .list-title {
    font-size: 1.3rem; }
  .list-title::before,
  .list-title::after {
    width: 20%; }
  .text {
    width: 60%; }
  .list-el div {
    padding: 10px; }
  .list-el div img {
    height: 30px !important;
    width: 30px !important; } }

@media (max-width: 1400px) {
  .box--traits {
    max-width: 90%; }
  .box {
    padding: 2rem 4rem; } }

@media (max-width: 700px) {
  .box {
    padding: 2rem 0; } }

@media (max-width: 600px) {
  .introduction {
    flex-direction: column;
    gap: 0; } }

.about {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  margin: 1rem; }
